import React, { Fragment } from 'react'
import { serialize } from 'dom-form-serializer'

import './Form.css'

class Form extends React.Component {
  static defaultProps = {
    name: 'Kontaktní formulář',
    subject: '', // optional subject of the notification email
    action: '',
    successMessage: 'Zpráva byla úspěšně odeslána.',
    errorMessage:
      'Objevil se problém a Vaše zpráva nebyla odeslána. Prosím kontaktujte nás emailem, nebo nám zavolejte.'
  }

  state = {
    alert: '',
    disabled: false
  }

  handleSubmit = e => {
    e.preventDefault();
    if (this.state.disabled) return;

    const form = e.target;
    const data = serialize(form);
    const encode = (data) => {
      return Object.keys(data)
        .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
    };
    this.setState({ disabled: true });
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        ...data
      }),
    })
    .then(() => {
      form.reset()
      this.setState({
        alert: this.props.successMessage,
        disabled: false
      })
    })
    .catch(err => {
      console.error(err)
      this.setState({
        disabled: false,
        alert: this.props.errorMessage
      })
    })
      
  }

  render() {
    const { name, subject, action } = this.props

    return (
      <Fragment>
        <form
          className="Form"
          name={name}
          data-netlify="true"
          method="POST"
          onSubmit={this.handleSubmit}
        >
          <input type="hidden" name="form-name" value={name} />
          {this.state.alert && (
            <div className="Form--Alert">{this.state.alert}</div>
          )}
          <label className="Form--Label">
            <input
              className="Form--Input Form--InputText"
              type="email"
              placeholder="Email"
              name="emailAddress"
              required
            />
            <span>Vaše emailová adresa</span>
          </label>
          <label className="Form--Label">
              <input
                className="Form--Input Form--InputText"
                type="text"
                placeholder="Předmět"
                name="subject"
                required
              />
              <span>Předmět</span>
            </label>
          <label className="Form--Label">
            <textarea
              className="Form--Input Form--Textarea Form--InputText"
              placeholder="Zpráva"
              name="message"
              rows="10"
              required
            />
            <span>Zpráva</span>
          </label>
          {!!subject && <input type="hidden" name="subject" value={subject} />}
          <input type="hidden" name="form-name" value={name} />
          <input
            className="Button Form--SubmitButton"
            type="submit"
            value="Odeslat"
            disabled={this.state.disabled}
          />
        </form>
      </Fragment>
    )
  }
}

export default Form
